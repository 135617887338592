.multiDynamicInputs {
  margin: 10px 0;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(0, 0, 0, 0.1);
  border: solid 0.5px;
  .multi {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    input {
      width: auto;
    }
  }
  .icons {
    display: flex;
    justify-content: flex-end;
    padding-right: 7.5%;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 10px;
  }
}

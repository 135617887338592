/* Style the header cell of the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-colCell[data-field="id"] {
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center-align the text */
}

/* Style the cells in the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-cell[data-field="id"] {
  text-align: center; /* Center-align the text in the cells */
}

/* Style the editable cells in the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-editCell[data-field="id"] {
  background-color: #fff; /* Change the background color of editable cells */
  /* Add a border around editable cells */
}
/* .custom-data-grid {
  font-family: Arial, sans-serif;
  border: 1px solid #e0e0e0;
  background-color: black;
 
} */
/* Style for the header row */
.MuiDataGrid-root .MuiDataGrid-header {
  background-color: white; /* Header background color */
  color: white; /* Header text color */
  font-weight: bold; /* Header text font-weight */
}

/* Style for the header cell when hovered */
.MuiDataGrid-root .MuiDataGrid-colHeader:hover {
  background-color: white; /* Background color on hover */
  cursor: pointer; /* Change cursor on hover */
}

/* Style for the sort icons in the header cells (assuming Material-UI DataGrid) */
.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: white; /* Color of sort icons */
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #001a35;
  border-radius: 15px;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 50px;
}
.MuiDataGrid-columnHeaders {
  color: white;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: white;
}
.css-vcjdx3 {
  background-color: #001a35;
  border-radius: 15px;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 50px;
  border: none;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  color: white;
}

/* .MuiDataGrid-cellContent{

} */
.css-wop1k0-MuiDataGrid-footerContainer {
  background-color: white;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 20px;
}
.css-9ffb5l {
  background-color: white;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 20px;
}
.css-axafay-MuiDataGrid-virtualScroller {
  overflow: auto;
  height: 100%;
  position: relative;
  background-color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: small;
}
.css-opb0c2 {
  overflow: auto;
  height: 100%;
  position: relative;
  background-color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: small;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  color: #001a35;
}
.MuiDataGrid-root .MuiDataGrid-colHeader {
  background-color: red;
  color: white;
  font-weight: bold;
  /* Add any other CSS styles you want to apply to the header cells */
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  color: rgb(99, 99, 99);
  background-color: #001a35;
}

::-webkit-scrollbar-track {
  background-color: white;
}
.css-ptkaw2-MuiDataGrid-root.MuiDataGrid-withBorderColor {
  border-color: white;
  background-color: white;
  padding: 10px;
}
.css-78d1bb {
  border-color: white;
  background-color: white;
  padding: 10px;
}
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(241, 241, 241); /* Set the background color for even rows */
  border-color: #0082aa;
  border-top: 1px solid #0082aa;
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
  background-color: white; /* Set the background color for odd rows */
}

/*New*/
.css-aop0yh .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(241, 241, 241); /* Set the background color for even rows */
  border-color: #0082aa;
  border-top: 1px solid #0082aa;
}
/*New*/
.css-aop0yh .MuiDataGrid-row:nth-child(odd) {
  background-color: white; /* Set the background color for odd rows */
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row {
  border-bottom: 1px solid #0082aa; /* Add a 1px solid gray border to the bottom of each row */
}

.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
  background-color: #caf3ff;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
}

.css-78d1bb:nth-child(odd) {
  background-color: white;
  border: none;
}
.css-78d1bb:nth-child(even) {
  background-color: rgb(241, 241, 241);
}
.css-78d1bb {
  border-bottom: 1px solid #0082aa;
}
.css-78d1bb:nth-child(even) {
  background-color: rgb(231, 231, 231);
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
  background-color: white;
  border-bottom: 1px solid #0082aa;
}
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(231, 231, 231);
  border-bottom: 1px solid #0082aa;
}
.css-78d1bb .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(231, 231, 231);
  border-bottom: 1px solid #0082aa;
}
.css-78d1bb .MuiDataGrid-row {
  border-bottom-color: #0082aa;
  border-bottom: inset #0082aa;
  border-width: thin;
}
.css-aop0yh .MuiDataGrid-row:nth-child(odd) {
  background-color: #e7e7e7;
  border-color: #0082aa;
  border-top: 1px solid #0082aa;
}

.css-78d1bb {
  border: none;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
  color: white;
}
.css-1j7qk7u {
  color: #fff !important;
}
.css-1j7qk7u:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
@media (hover: hover) {
  .css-78d1bb .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon {
    opacity: 1;
  }
}

.MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root
  > svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
  color: #000000;
}

li > ul {
  transform: translatex(100%) scale(0);
}
li:hover > ul {
  transform: translatex(101%) scale(1);
}
li > button svg {
  transform: rotate(-90deg);
}
li:hover > button svg {
  transform: rotate(-270deg);
}

.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}
.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.scale-0 {
  transform: scale(0);
}
.min-w-32 {
  min-width: 8rem;
}

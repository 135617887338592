/* Optional: Adjust the size of the calendar cells */
.compact-calendar .react-datepicker__input-container input {
  width: 5px; /* Adjust width */
  height: 5px; /* Adjust height */
}
.react-datepicker-popper {
  width: 500px;
  height: 700px !important;
}

/* .custom-date-picker .DayPicker_weekdays {
  display: flex;
  justify-content: space-around;
} */

.custom-date-picker .DayPicker_weekday {
  color: blue;
}

.custom-date-picker .DayPicker_weekday:nth-child(1)::before {
  content: "Sunday";
}

.custom-date-picker .DayPicker_weekday:nth-child(2)::before {
  content: "Monday";
}

.custom-date-picker .DayPicker_weekday:nth-child(3)::before {
  content: "Tuesday";
}

.DayPicker_weekHeader_li small {
  font-size: 12px !important;
  font-weight: 600;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  display: none !important;
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  display: none !important;
}
.DayPickerNavigation_prevButton__verticalScrollableDefault.DayPickerNavigation_prevButton__verticalScrollableDefault_7 {
  display: none;
}

@media screen and (max-width: 1000px) {
  .DayPicker_weekHeader_li {
    width: 42px !important;
  }
  .DateRangePicker_picker {
    /* left: -52.2969px !important; */
    z-index: 10000 !important;
  }
  .DateRangePicker_closeButton_svg {
    height: 25px;
    width: 25px;
    fill: darkred;
    border: 1px solid #002244;
    padding: 4px;
    border-radius: 50px;
  }
  .CalendarMonth_table {
    margin-top: 10px !important;
  }
  .DateRangePicker_closeButton {
    margin-top: 20px;
    padding: 15px;
    z-index: 2;
  }
  .DayPickerNavigation_svg__vertical {
    margin: auto !important;
  }
}

@use "/src/variables";

.recentTransactionsElement {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid;
  div {
    display: flex;
    flex-direction: column;
  }
  .code {
    font-size: 1.25rem;
    font-weight: 900;
    color: variables.$dashboard_main;
  }
  .price {
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: variables.$dashboard_main;
  }
}

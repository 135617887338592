.dashNavButtons {
  display: flex;
  height: fit-content;
  gap: 10px;
  margin-right: 10px;
}
.dashNavButtons {
  svg {
    width: 25px;
  }
  button {
    padding: 10px;
    color: white;
    border-radius: 7.5px;
  }
  svg:hover {
    cursor: pointer;
  }
}

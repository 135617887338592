@use "/src/variables";

.dashboardRevenueChart {
  width: calc(60% - 40px - 15px);
  height: 510;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  border-radius: 7.5px;
  padding: 20px;
  margin-top: 20px;
}

.dashboardRevenueChart:hover {
  transform: scale(1.05);
}

.cover_img {
  background-image: url("./12login.jpg");
}

/* .cover_img_mobile {
  background-image: url("./5login.jpg");
  display: none;
} */

@media screen and (max-width: 1024px) {
  .cover_img_mobile {
    background-image: url("./12login.jpg");
  }
}

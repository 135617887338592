@use "/src/variables";

.dashNavSearch {
  position: relative;
}
.dashNavSearch {
  input {
    width: 250px;
    height: 35px;
    background-color: variables.$dashboard_background;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-left: 10px;
    border-radius: 5px;
  }
  svg {
    width: 25px;
    height: auto;
    position: absolute;
    right: 5px;
    top: 5px;
    color: rgb(0, 0, 0, 0.6);
  }
}

@use "/src/variables";
.data-grid {
  width: calc(80vw);
  .operations {
    display: flex;
  }
  svg {
    width: 25px;
  }
  svg:hover {
    cursor: pointer;
    color: green;
  }
}
.deleteCard {
  position: fixed;
  width: max(25vw, 200px);
  border-radius: 15px;
  height: 200px;
  top: 200px;
  left: max(37.5vw, 100px);
  background-color: variables.$dashboard_background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: solid 1px black;
}

.deleteCardButtons {
  display: flex;
  gap: 20px;
}
.loader {
  margin-left: calc(50vw - 250px);
  margin-top: 40vh;
}

@media screen and (max-width: 1000px) {
  .data-grid {
    width: 95vw;
  }
}

.breadcrumbs {
  /* margin: 20px; */
  /* float: left; */
  /* background: #c7dbf3a1; */
}
.breadcrumbs > * {
  display: inline-block;
  margin-right: 10px;
}
.breadcrumbs .crumb:after {
  content: "\276F";
  margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}
.breadcrumbs .crumb a {
  color: #002244;
}

@use "/src/variables";

.dashRevenueHeadings {
  margin: auto;
  width: 95%;
  height: 25%;
  display: flex;
  justify-content: space-between;
}

.dashRevenueHeadings {
  svg {
    width: 25px;
    align-self: flex-end;
    height: 25px;
    border-radius: 50%;
  }
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 900;
    color: variables.$dashboard_main;
  }
  svg:hover {
    background-color: variables.$dashboard_background;
    cursor: pointer;
  }
}

@use "/src/variables";

.dashboard {
  display: flex;
  background-color: white;
  width: 100%;
}

.dashboard {
  .charts {
    display: flex;
    justify-content: space-between;
    width: calc(75vw);
    height: fit-content;
    margin-left: 15px;
  }
  .bottom-charts {
    display: flex;
    justify-content: space-between;
    width: calc(75vw);
    height: fit-content;
    margin-bottom: 25px;
    margin-left: 15px;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #fff;
}

// night-mode
.dashboard-night {
  background-color: #3a3939 !important;
  color: white;

  .sidebar {
    background-color: #0c0c0c !important;
  }
  .dashboardRevenueChart {
    background-color: #0c0c0c !important;
  }
  .dashCampaign {
    background-color: #0c0c0c !important;
  }
  .dashStatesCard {
    background-color: #0c0c0c !important;
    svg {
      color: white;
    }
  }
  .dashClockCard {
    background-color: #0c0c0c !important;
    svg {
      color: white !important;
    }
  }
  .dashboardRecentTransactions {
    background-color: #0c0c0c !important;
  }
  .dashSalesQuantity {
    background-color: #0c0c0c !important;
  }
  .dashHeading {
    button {
      background-color: #0c0c0c;
    }
    button svg {
      color: white !important;
    }
  }
  .dashNavButtons {
    svg {
      color: white;
    }
    button {
      background-color: #0c0c0c;
    }
  }
  .sidebarElement {
    svg {
      color: white;
    }
  }
}

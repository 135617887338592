@use "/src/variables";

.dashboardForm {
  // margin-top: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  // padding-top: 20px;
  padding-bottom: 20px;
  // background-color: variables.$dashboard_background;
  // box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 7.5px;
  width: 80vw;
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .flex-column-20 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .flex-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .flex-row-spaceBetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  // input[type="text"],
  // input[type="number"],
  // input[type="date"],
  // input[type="email"],
  // textarea,
  // select,
  // #features {
  //   border-radius: 10px;
  //   box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  //   width: max(35vw, 200px);
  // }
  input[type="file"] {
    border-radius: 0;
  }
  input[type="submit"] {
    width: 200px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  }
  .radioSpan {
    margin-right: 20px;
  }
  .radioSpan label {
    padding-left: 10px;
  }
  .icons {
    align-self: flex-end;
  }
  svg {
    width: 20px;
    margin-top: 7.5px;
    display: inline;
  }
  svg.plus:hover {
    color: green;
    cursor: pointer;
  }
  svg.minus:hover {
    color: red;
    cursor: pointer;
  }
  .multiple {
    margin-bottom: 5px;
  }
  .file {
    border-radius: 0;
  }
  .mapContainer {
    height: 400px;
    width: 70vw;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .error {
    color: red;
  }
  .files-preview {
    display: flex;
  }
  .file-container {
    margin-left: 10px;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  .oldFiles {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }
  input[type="text"].DateInput_input {
    box-shadow: none;
    width: 75px;
    border: none;
  }
  input[type="number"].i15vw {
    width: 7.5vw;
  }
}
.loader {
  margin-left: calc(50vw - 250px);
  margin-top: 40vh;
}
.section {
  margin-bottom: 40px;
}

@media screen and (max-width: 1000px) {
  .dashboardForm {
    width: calc(100vw - 60px);
  }
}
.formHeading {
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px 10px;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 7.5px;
  font-size: 2rem;
  font-weight: 900;
}

.timerCard {
  padding: 10px 10px;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 7.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 900;
}

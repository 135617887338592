.currencyHistoryDetails {
  margin-top: 20px;
  margin-left: 20px;
  table {
    border: solid #002244;
    width: 70vw;
  }
  td {
    font-weight: 700;
  }
}

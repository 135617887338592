@use "/src/variables";
.hotelDetailsCard {
  // margin-top: 3px;
  // margin-left: 7px;
  // background-color: variables.$dashboard_background;
  width: 100%;

  .hotelNameCard {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    width: 99%;
    padding: 10px;

    .flex {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .hotelName {
      font-size: 1.5rem;
      font-weight: 900;
    }
  }

  .hotelImageSection {
    width: 99%;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    display: flex;
  }
  .imageSectionText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .row {
      padding: 0px 10%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 40vw;
    }
  }

  .aboutCard {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    width: 99%;
    padding: 10px;
    margin-bottom: 20px;
  }
  .flex-btns {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .action-btn {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: #002244;
    color: white;

    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  .action-btn-delete {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: rgb(102, 3, 3);
    color: white;

    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  .action-btn-edit {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: rgb(11, 59, 5);
    color: white;

    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  .loader {
    margin-left: calc(50vw - 250px);
    margin-top: 40vh;
  }
  .hotelRoomsCard {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    width: 99%;
    padding: 10px;
    margin-bottom: 20px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* To fix html2canvas text shifting down bug */
@layer base {
  img {
    @apply inline-block;
  }
}
/* Target the DatePicker dropdown globally */
.ant-picker-dropdown {
  max-width: 80vw !important; /* Ensure the popup fits within the screen */
  box-sizing: border-box; /* Prevent overflow issues */
}

/* Adjust the months layout for small screens */
@media (max-width: 768px) {
  .ant-picker-panel-container {
    display: flex;
    flex-direction: column !important; /* Stack months vertically */
  }

  /* Ensure individual panels (months) are full-width */
  .ant-picker-date-panel {
    width: 100% !important;
  }

  /* Optional: Center align the content */
  .ant-picker-cell-inner {
    text-align: center;
  }
}

.ant-pagination-options {
  display: flex !important;
  justify-content: start;
  align-items: start;
}

.ant-pagination-options-quick-jumper {
  display: flex !important;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.ant-pagination {
  display: flex !important;
  justify-content: start !important;
}

/* .ant-table-tbody > tr > td {
  padding-left: 0 !important;
} */
/* ant-table-pagination ant-table-pagination-right css-dev-only-do-not-override-98ntnt */
body {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

@font-face {
  font-family: "bein-ar";
  src: url("/public/fonts/bein-ar-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.custom-header-class {
  width: 100% !important;
}

.custom-cell-class {
  width: 100% !important;
}

.custom-header {
  background-color: #002244;
  color: #fff;
  font-weight: bold;
  /* width: 100% !important; */
  /* font-size: minmax(1rem, 2rem, 4vw) !important; */
}

.ant-table {
  display: block;
  margin: 1rem auto !important;
  background: #f9fafb !important;
  font-weight: bold !important;
  width: 100% !important;
  text-align: center;
  min-width: max-content !important;
  width: 100%;
}

/* .ant-spin-container {
  display: flex;
  width: 100% !important;
  max-width: max-content !important;
  flex-grow: 1;
  flex-direction: column;
  align-content: stretch;
} */

/* @media screen and (min-width: 100px) {
  .ant-spin-container {
    width: 100% !important;
    max-width: max-content;
  }
  .ant-table {
    width: 100% !important;
    max-width: max-content;
  }
} */
/* @media screen and (max-width: 1700px) {
  .ant-spin-container {
    min-width: max-content !important;
    width: 100%;
  }
} */
.ant-select-selection-placeholder {
  @apply !text-gray-700;
}

.ant-table-cell > a:hover {
  color: #fafafa !important;
}

.ant-table-tbody > tr:hover > * {
  color: #fafafa !important;
}

.ant-table-thead tr th:last-child {
  text-align: center !important;
  justify-content: center;
}

.ant-table-thead .ant-table-cell {
  border-bottom: none !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #002244 !important;
  color: #fff !important;
  width: max-content !important;
}

.ant-table-thead > tr > th.custom-header {
  background-color: #002244;
  /* width: max-content !important; */
  border-bottom: none !important;
}

.ant-pagination-prev {
  rotate: 90deg !important;
}

.ant-pagination-prev:hover {
  rotate: 0;
}

.ant-pagination-next {
  rotate: 90deg !important;
}

.ant-table-tbody > tr:nth-child(odd) {
  background-color: #e7e7e7 !important;
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: #eeeeee !important;
}

.ant-table-tbody > tr:last-child {
  border-bottom: none !important;
}

.ant-table-tbody > tr:first-child {
  border-top: none !important;
}

.ant-table-tbody > tr:hover > * {
  background-color: #b7b7b7 !important;
}

.ant-table-cell {
  width: max-content !important;
  padding: 0.5rem 0.5rem !important;
}

@media screen and (max-width: 980px) {
  .ant-table-cell {
    padding: 0.5rem 0.5rem !important;
  }
}

.custom-row {
  background-color: #fff;
}

.custom-row:hover {
  background-color: #f5f5f5;
}

.container {
  @apply w-11/12 mx-auto;
}

.blur-effect-theme {
  @apply bg-white/30 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
}

.blur-effect-theme-loading {
  @apply bg-black/80 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
}

.blur-effect-theme2 {
  @apply bg-black/70 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
}

.bg-theme-cart {
  @apply bg-gradient-to-b from-slate-900 to-gray-900 shadow-sm shadow-gray-900;
}

.button-theme {
  @apply md:px-7 md:py-1 rounded active:scale-90 transition-all duration-300  ease-in-out shadow-md text-sm p-1 hover:scale-105;
}

.step-item {
  @apply relative flex flex-col justify-center items-center w-36;
}

.step-item:not(:first-child):before {
  @apply content-['']  absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4 text-black;
}

.step {
  @apply w-8 h-8 sm:w-10 sm:h-10  flex items-center justify-center z-10 relative  rounded-full font-semibold text-white;
}

/* .active .step {
    @apply bg-[#ffd000d8];
  } */
.complete .step {
  @apply bg-black;
}

.complete p {
  @apply text-black;
}

.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-black text-black;
}

.btn {
  @apply bg-gray-800 hover:bg-gray-700 text-gray-100 font-medium px-7 py-1.5 rounded;
}

/* border-[#00224493] border-b */
.header-h2 {
  @apply flex items-center text-center    md:text-2xl   gap-2  text-[#002244] font-bold  w-full text-xl    py-2;
}

.input-par {
  @apply flex w-full max-sm:flex-col gap-4;
}

.input-chil {
  @apply flex-1 max-sm:w-full;
}

.header-enhance {
  @apply bg-white rounded-xl w-max sm:px-6 px-4 ml-[3px] py-1.5 my-3;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 2px,
    rgba(0, 0, 0, 0.01) 0px 1px 2px;
}

.form-wrap {
  @apply mt-6 bg-white rounded-xl w-full sm:px-6 px-4 py-8 lg:max-w-7xl md:max-w-5xl mx-auto;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px,
    rgba(0, 0, 0, 0.07) 0px 5px 5px;
}

/* .form-wrap {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 3px,
    rgba(0, 0, 0, 0.07) 0px 15px 15px;
} */
.formPage-wrap {
  @apply w-full   md:px-6 px-2 py-8  h-full;
}

.addButton {
  @apply flex items-center gap-2 text-center justify-center text-gray-50 rounded-lg capitalize tracking-wider font-semibold md:w-max w-full px-8 py-2 bg-green-600 hover:scale-105 hover:bg-green-500 hover:shadow-md active:scale-95 duration-300 transition-all;
}

.updateButton {
  @apply md:w-max w-full flex items-center gap-2 text-center justify-center tracking-wider font-semibold text-gray-50 rounded-lg capitalize px-8 py-2 bg-blue-600 hover:scale-105 hover:bg-blue-500 hover:shadow-md active:scale-95 duration-300 transition-all;
}

/* box-shadow:
rgba(0, 0, 0, 0.1) 0px 0px 3px,
rgba(0, 0, 0, 0.07) 0px 15px 15px;
} */
/* input {
  @apply bg-gray-50  !important;
} */
/* input::placeholder {
  padding-left: 5px;
} */
/* Style the header cell of the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-colCell[data-field="id"] {
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center-align the text */
}

/* Style the cells in the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-cell[data-field="id"] {
  text-align: center; /* Center-align the text in the cells */
}

/* Style the editable cells in the 'ID' column */
.MuiDataGrid-root .MuiDataGrid-editCell[data-field="id"] {
  background-color: #fff; /* Change the background color of editable cells */
  /* Add a border around editable cells */
}

/* .custom-data-grid {
  font-family: Arial, sans-serif;
  border: 1px solid #e0e0e0;
  background-color: black;
 
} */
/* Style for the header row */
.MuiDataGrid-root .MuiDataGrid-header {
  background-color: white; /* Header background color */
  color: white; /* Header text color */
  font-weight: bold; /* Header text font-weight */
}

/* Style for the header cell when hovered */
.MuiDataGrid-root .MuiDataGrid-colHeader:hover {
  background-color: white; /* Background color on hover */
  cursor: pointer; /* Change cursor on hover */
}

/* Style for the sort icons in the header cells (assuming Material-UI DataGrid) */
.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: white; /* Color of sort icons */
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #001a35;
  border-radius: 15px;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 50px;
}

.MuiDataGrid-columnHeaders {
  color: white;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: white;
}

.css-vcjdx3 {
  background-color: #001a35;
  border-radius: 15px;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 50px;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  color: white;
}

/* .MuiDataGrid-cellContent{

} */
.css-wop1k0-MuiDataGrid-footerContainer {
  background-color: white;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 20px;
}

.css-9ffb5l {
  background-color: white;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
  height: 20px;
}

.css-axafay-MuiDataGrid-virtualScroller {
  overflow: auto;
  height: 100%;
  position: relative;
  background-color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: small;
}

.css-opb0c2 {
  overflow: auto;
  height: 100%;
  position: relative;
  background-color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: small;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  color: #001a35;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  color: rgb(99, 99, 99);
  background-color: #001a35;
}

::-webkit-scrollbar-track {
  background-color: white;
}

.css-ptkaw2-MuiDataGrid-root.MuiDataGrid-withBorderColor {
  border-color: white;
  background-color: white;
  padding: 10px;
}

.css-78d1bb {
  border-color: white;
  background-color: white;
  padding: 10px;
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(241, 241, 241); /* Set the background color for even rows */
  border-color: #0082aa;
  border-top: 1px solid #0082aa;
}

/*New*/
.css-aop0yh .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(241, 241, 241); /* Set the background color for even rows */
  border-color: #0082aa;
  border-top: 1px solid #0082aa;
}

/*New*/
.css-aop0yh .MuiDataGrid-row:nth-child(odd) {
  background-color: white; /* Set the background color for odd rows */
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
  background-color: white; /* Set the background color for odd rows */
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row {
  border-bottom: 1px solid #0082aa; /* Add a 1px solid gray border to the bottom of each row */
}

.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
  background-color: #caf3ff;
  font-weight: 700;
  text-decoration-line: none;
  font-size: small;
}

.css-78d1bb:nth-child(odd) {
  background-color: white;
}

.css-78d1bb {
  border-bottom: 1px solid #0082aa;
}

.css-78d1bb:nth-child(even) {
  background-color: white;
}

.acc_settings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* Styles for react-phone-number-input component  */
/* https://www.npmjs.com/package/react-phone-number-input */

.PhoneInput {
  @apply w-full p-2 border border-gray-300 rounded-md;
}

.PhoneInput--focus {
  @apply border-2 border-gray-900;
}

.PhoneInputInput {
  border: none !important;
  outline: none;
}

.PhoneInput--focus .PhoneInputInput {
  border: 1px solid #888888;
  border-bottom: 1px solid #888888 !important;
  outline: none;
}

.PhoneInput--disabled,
.PhoneInputInput:disabled,
.PhoneInputCountrySelect:disabled {
  @apply bg-gray-100;
  cursor: not-allowed;
}

.go1754112896 {
  bottom: 45px !important;
}

input {
  outline: none;
}

/* customStyles.css */
.CalendarDay__highlighted_red {
  background-color: red !important;
  color: white;
}

.Header_modal_search > [aria-label="Close"] {
  width: 15px;
  margin-left: auto;
}

div[modal-backdrop] {
  background-color: rgb(17 24 39 / 50%);
}

@media (prefers-color-scheme: dark) {
  div[modal-backdrop] {
    background-color: rgb(17 24 39 / 80%);
  }
}

@media screen and (max-width: 1000px) {
  .DayPicker_weekHeader_ul {
    display: flex;
    justify-content: center;
  }
}

/* Ribbon 1 */
.top-cross-ribbon {
  /* background: #e22122; */
  background: #e22122;
  padding: 6px 35px;
  color: #fff;
  position: absolute;
  top: 20px;
  /* border: 1px dashed #fff; */
  /* box-shadow: 0 0 0 3px #e22122; */
  margin: 5px;
}

.ribbon2 {
  width: 50px;
  padding: 18px 0;
  position: absolute;
  top: -6px;
  text-align: center;
  border-top-left-radius: 3px;
  background: #59324c;
}

.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid black;
  border-right: 6px solid transparent;
}

.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}

.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -20.5px;
  left: 0;
  border-left: 25px solid #59324c;
  border-right: 25px solid #59324c;
  border-bottom: 22px solid transparent;
}

.colorChange {
  animation: colorChange 800ms infinite;
}

@keyframes colorChange {
  0% {
    color: #ffffff;
  }
  50% {
    color: #0000;
  }
  100% {
    color: #ffffff;
  }
}

.showHide {
  animation: showHide 800ms infinite;
}

@keyframes showHide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Define the keyframes for the scale animation */
@keyframes scaleUp {
  0% {
    transform: scale(1); /* Start at the original size */
  }
  50% {
    transform: scale(1.2); /* Scale up to 120% */
  }
  100% {
    transform: scale(1); /* Return to the original size */
  }
}

/* Apply the animation to an element */
.scale-animation {
  display: inline-block;
  animation: scaleUp 1s ease-in-out infinite;
}

/* //ribbon in search//  */
:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon.ant-ribbon-placement-end {
  inset-inline-end: -8px;
  border-end-end-radius: 0;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class^="ant-ribbon"],
:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class*=" ant-ribbon"] {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0 8px;
  color: #1677ff;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  top: 8px;
  white-space: nowrap;
  background-color: #1677ff;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon .ant-ribbon-text {
  color: #fff;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class^="ant-ribbon"],
:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class*=" ant-ribbon"] {
  box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  inset-inline-end: 0;
  border-inline-end-color: transparent;
  border-block-end-color: transparent;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon .ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
  filter: brightness(75%);
}

:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class^="ant-ribbon"],
:where(.css-dev-only-do-not-override-98ntnt).ant-ribbon-wrapper [class*=" ant-ribbon"] {
  box-sizing: border-box;
}

.email-body ul {
  padding: 0 1rem;
  margin: 0 1rem;
  list-style-type: square;
}

/* connectedt room pulse  */
.pulse-alert {
  position: relative;
  padding: 0.2rem 0.5rem;
  font-size: 10px;
  border: 2px solid transparent; /* Base border */
  border-radius: 10px;
  color: #fff;
  background: transparent; /* Base background */
  z-index: 0;
  text-align: center;
  width: max-content;
}

.pulse-alert:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 255, 1); /* Semi-transparent blue */
  border-radius: 10px;
  animation: pulse 5s infinite;
  z-index: -1;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

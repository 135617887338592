.boxShadow {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 3px,
    rgba(0, 0, 0, 0.07) 0px 15px 15px;
}
[aria-label="Tabs with icons"] button {
  font-size: 20px !important;
}

@media screen and (max-width: 1000px) {
  [aria-label="Tabs with icons"] button {
    font-size: 15px !important;
  }
}

.chatContainer {
  box-shadow: 3px 3px 15px 2px rgba(0, 0, 0, 0.2);
  margin-top: 5vh;
  .chatHeader {
    height: 30px;
    transition: transform 0.3s ease; /* Add a smooth transition on the 'transform' property */
    transform: translateY(0); /* Initially, the chatHeader is not translated */
  }

  .chatContainer.open .chatHeader {
    transform: translateY(-30px); /* When chatArea is open, slide chatHeader upward */
  }
  .chatArea {
    height: calc(50vh - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chevron-rotated {
    transform: rotate(180deg);
  }
  .rce-mbox-right {
    background-color: #d9fdd3;
    svg {
      fill: #d9fdd3;
    }
  }
}

.custom-input {
  position: relative;
  width: 200px;
  height: 40px;
  color: white;
}

.custom-input:before {
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 3px;
  content: attr(data-date);
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.custom-input::-webkit-datetime-edit,
.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-clear-button {
  display: none;
}

.custom-input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 3px;
  right: 0;
  color: black;
  opacity: 1;
}

@use "/src/variables";
.dashHeading {
  display: flex;
  width: calc(75vw);
  justify-content: space-between;
  margin-left: 15px;
}

.dashHeading {
  button {
    display: flex;
    gap: 10px;
    align-self: flex-end;
    background-color: variables.$dashboard_background;
    padding: 7.5px 12.5px;
    border-radius: 7.5px;
    font-weight: 700;
  }
  svg {
    width: 25px;
  }
  h1 {
    font-weight: 900;
    font-size: 3rem;
  }
  p {
    padding: 0;
    margin: 0;
    // font-size: 1.2rem;
  }
}

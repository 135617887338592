@use "/src/variables";
.dashCampaign {
  width: calc(50% - 10px);
  max-height: 300px;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  border-radius: 7.5px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  svg {
    width: 20px;
  }
  h1 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .info {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .orange {
    font-size: 1.25rem;
    font-weight: 600;
    color: variables.$dashboard_main;
  }
}

.dashCampaign:hover {
  transform: scale(1.05);
}

@use "/src/variables";
.contractDetails {
  width: 100%;
  .loadingGif {
    margin-left: 30vw;
    margin-top: 40vh;
  }
}
.mainHeading {
  width: fit-content;
  margin: auto;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 20px;
}
.btns {
  .flex1 {
    display: flex;
    width: fit-content;
    margin: auto;
    gap: 15px;
    :hover {
      background-color: #002244;
      // color: ;
    }
  }
  .bt {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: #888;
    color: #fff;
    // background-color: variables.$dashboard_main_08;
    border: solid 1px variables.$dashboard_main;
    // box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;

    svg {
      width: 20px;
    }
  }
}

.contract {
  max-width: 1477px;
  width: 95%;
  min-height: 100vh;
  // background-color: #fff;
  margin: 0 auto;

  padding: 2rem 6px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Times New Roman, Times, serif";

  .contractHeading {
    margin: auto;
    width: fit-content;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .generalDetails {
    // display: flex;
    // justify-content: flex-start;
    // gap: 25%;
  }
  .subHeading {
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 20px;
  }
  .subHeading2 {
    font-size: 1.15rem;
    font-weight: 600;
    text-decoration: underline;
    margin-top: 10px;
  }
  span {
    font-weight: 600;
  }
  .validity-section {
    page-break-inside: avoid;
    padding: 10px;
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    .validity-section {
      width: auto;
    }
  }
  .page-break {
    page-break-before: always;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }
}

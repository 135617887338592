.bg-image {
  background-image: url("./Venice-Italy-Wallpaper-HD.jpg");
}

@media screen and (min-width: 1700px) {
  .divSlider {
    width: 100rem /* 1440px */;
    height: 40rem /* 640px */;
  }
  .imgSlider {
    height: 30.938rem /* 480px */;
  }
}

input[type="date"].floating-input {
  position: relative;
}

input[type="date"].floating-input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: -6px;
  height: 50px;
  width: 500px;
  opacity: 0;
}

.ant-btn-sm {
  background-color: #002244;
}
@media print {
  body {
    text-align: center; /* Center content horizontally */
  }
  div.center-content {
    position: absolute;
    top: 10%;
    /* left: 50%; */

    /* transform: translate(-50%, -50%); */
  }
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 900px;
  padding: 5px;
  height: 100vh;
}
[role="tooltip"].popup-content {
  width: 800px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
[role="tooltip"].my-popup-content {
  width: 800px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 900px;
  padding: 5px;
  height: 100vh;
}

@use "/src/variables";
.roomDetailsCard {
  // margin-top: 3px;
  // margin-left: 7px;
  // background-color: variables.$dashboard_background;
  width: 100%;

  .roomNameCard {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    width: 99%;
    padding: 10px;

    .roomName {
      font-size: 1.5rem;
      font-weight: 900;
    }
  }
  .flex-btns {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .action-btn {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: #002244;
    color: white;
    border: solid 1px variables.$dashboard_main;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  .action-btn-delete {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: rgb(102, 3, 3);
    color: white;
    border: solid 1px variables.$dashboard_main;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  .action-btn-edit {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: rgb(12, 66, 5);
    color: white;
    border: solid 1px variables.$dashboard_main;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    svg {
      width: 20px;
      color: white;
    }
  }
  // .flex {
  //   display: flex;
  //   justify-content: space-between;
  //   gap: 10px;
  //   margin-top: 10px;
  // }
  .color {
    background-color: variables.$dashboard_main_01;
  }
  .tableDiv {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    // width: 99%;
    padding: 10px;
  }
}

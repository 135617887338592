@use "/src/variables";
@font-face {
  font-family: "digital-clock";
  src: url("../../../../public/fonts/digital-7.ttf") format("woff");
}

.timer {
  display: flex;
  justify-content: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: inherit;
  color: white;
  padding: 5px;
  border-radius: 7.5px;
  // width: 150px;
  // border: 1px solid white;
  // background-color: rgba(255, 255, 255, 0.7);
  // box-shadow: 3px 3px 15px 2px rgba(0, 0, 0, 0.2);
}

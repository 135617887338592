@use "/src/variables";
.dashboardRecentTransactions {
  width: calc(43%);
  max-height: 510px;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  border-radius: 7.5px;
  padding: 20px;
  margin-top: 20px;
  overflow-x: auto;
}

.dashboardRecentTransactions {
  h1 {
    font-size: 1.5rem;
    font-weight: 900;
    padding-bottom: 20px;
    border-bottom: solid;
  }
}

.dashboardRecentTransactions:hover {
  transform: scale(1.05);
}

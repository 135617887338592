@use "/src/variables";
.Inventory {
  .table-container {
    width: 75vw;
    margin: auto;
  }
  button {
    margin-top: 10px;
    width: 200px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  }
  td {
    border: solid white 2px;
  }
}

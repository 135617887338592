@use "/src/variables";
.deleteCard {
  position: fixed;
  width: max(25vw, 200px);
  border-radius: 15px;
  height: 200px;
  top: 200px;
  left: max(37.5vw, 100px);
  background-color: variables.$dashboard_background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: solid 1px black;
}

.contractFormDynamicComponent {
  margin: 20px 0;
  padding: 20px;
  // border-radius: 20px;
  // background-color: rgb(0, 0, 0, 0.1);
  // border: solid 0.5px;
  // .flex {
  //   display: flex;
  //   justify-content: space-around;
  // }
  // .flex-column {
  //   display: flex;
  //   flex-direction: column;
  // }
  .icons {
    align-self: flex-end;
    margin-right: 2%;
  }
  .child {
    margin-top: 10px;
    border-radius: 30px;
    width: 92%;
    align-self: center;
    border: solid 1px rgb(0, 0, 0, 0.2);
    input,
    select {
      width: 30vw;
    }
  }
  .inputsContainer {
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: rgb(255, 255, 255, 0.7);
  }
  h1 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  .parent {
    margin-top: 20px;
  }
  .dateNote {
    font-size: 0.75rem;
    color: green;
  }
}

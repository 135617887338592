@use "/src/variables";
.roomsDynamicCards {
  .fourRoomsCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .oneRoomCard {
    box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
    border-radius: 7.5px;
    width: 45%;
    padding: 10px;
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 10px;

    h2 {
      font-weight: 700;
    }
    h2:hover {
      color: variables.$dashboard_main;
      cursor: pointer;
    }
  }
}

@use "/src/variables";
.dashSalesQuantity {
  width: calc(50% - 10px);
  max-height: 300px;
  background-color: variables.$dashboard_background;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 7.5px;
  padding: 20px;
  margin-top: 20px;

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.dashSalesQuantity:hover {
  transform: scale(1.05);
}

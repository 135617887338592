@use "/src/variables";

.hotelDetailDynamicCard {
  margin-top: 10px;
  margin-bottom: 20px;
  // box-shadow: 2px 2px 10px 1px variables.$dashboard_boxShadow;
  border-radius: 7.5px;
  min-height: 600px;
  width: 99%;
  padding: 10px;
  .chooseHeaders {
    display: flex;
    gap: 20px;
    font-weight: 500;
    border-bottom: solid 3px rgb(177, 173, 173, 0.3);
    height: 25px;
    margin-bottom: 25px;

    h1 {
      cursor: pointer;
    }
  }

  .video,
  .services,
  .map {
    height: 25px;
    color: #002244;
    border-bottom: solid 3px #002244;
  }
  .color {
    background-color: variables.$dashboard_main_01;
  }
}
